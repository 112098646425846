import ComingSoonComponent from '@components/pages/coming-soon'
import { NextPage } from 'next'
import Head from 'next/head'

const ComingSoonPage: NextPage = () => {
  return (
    <>
      <Head>
        <title>Voucci - Coming Soon</title>
      </Head>
      <ComingSoonComponent />
    </>
  )
}

export default ComingSoonPage
