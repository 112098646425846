import styled from '@emotion/styled'

import theme from '@lib/theme'

export const ComingSoonContainer = styled.div({
  backgroundColor: theme.colors.primary,
  alignItems: 'center',
  display: 'flex',
  height: '100vh',
  justifyContent: 'center',
  width: '100vw',
})

export const ContentContainer = styled.div({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  width: '100%',
})

export const Heading = styled.h1({
  color: theme.colors.white,
  marginTop: '30px',
  textAlign: 'center',
  width: '100%',
})

export const Logo = styled.img({
  maxHeight: '60px',
})
