import { FC } from 'react'
import { ComingSoonContainer, ContentContainer, Heading, Logo } from './style'

const ComingSoonComponent: FC = () => {
  return (
    <ComingSoonContainer>
      <ContentContainer>
        <Logo src="/images/voucci-logo.svg" alt="Voucci" />
        <Heading>Coming Soon</Heading>
      </ContentContainer>
    </ComingSoonContainer>
  )
}

export default ComingSoonComponent
