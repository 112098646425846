import { type Theme } from '@emotion/react'

const theme: Theme = {
  colors: {
    primary: '#FF7334',
    light: '#f7f8f8',
    text: '#585554',
    textDark: '#1A1918',
    textLight: '#A3A3A3',
    white: 'white',
  },
}

export enum ModuleThemes {
  Default = 'default',
  Primary = 'primary',
  Secondary = 'secondary',
}

export const moduleThemeMap = {
  [ModuleThemes.Default]: {
    backgroundColor: theme.colors.white,
    color: theme.colors.text,
    headingColor: theme.colors.textDark,
    linkColor: theme.colors.primary,
  },
  [ModuleThemes.Primary]: {
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    headingColor: theme.colors.white,
    linkColor: theme.colors.white,
  },
  [ModuleThemes.Secondary]: {
    backgroundColor: theme.colors.light,
    color: theme.colors.text,
    headingColor: theme.colors.textDark,
    linkColor: theme.colors.primary,
  },
}

export default theme
